/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://fiqq5pqiqzbddnnlhuuva44dqm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-fanwy746rvb5rlojqdugykgm7e",
    "aws_content_delivery_bucket": "ciccia-lab-website-20200507221357-hostingbucket-cicciac",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3aouq5s3rqf5t.cloudfront.net"
};


export default awsmobile;
